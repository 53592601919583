@use "sass:math";

$cubic-bezier: cubic-bezier(0, 1.16, 1, 1);

$breakpoint: 769px;
$breakpoint-min: 768px;

$desktop-nav-width: 1200px;
$circle-nav-toggle-color: #191919;
$circle-nav-toggle-weight: 30px;
$circle-nav-toggle-height: 30px;

$circle-nav-panel-gradient-bottom: #ffc371;
$circle-nav-panel-gradient-top: #ff5f6d;
$circle-nav-panel-width: 450px;
$circle-nav-panel-height: 450px;

$circle-nav-item-bg: #fff;

@media (max-width: $breakpoint-min) {
    $show-icon: true;
    $show-text: false;
    .circle-nav-wrapper {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 9999999;
        width: math.div($circle-nav-panel-width, 2);
        height: math.div($circle-nav-panel-height, 2);
        overflow: hidden;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

        // Toggle button
        .circle-nav-toggle {
            display: flex;
            position: absolute;
            top: 10px;
            right: 10px;
            justify-content: center;
            align-items: center;
            z-index: 999999;
            transition: transform 0.2s cubic-bezier(0, 1.16, 1, 1);
            border: 2px solid $circle-nav-toggle-color;
            border-radius: 50%;
            width: $circle-nav-toggle-weight;
            height: $circle-nav-toggle-height;
            user-select: none;

            i.material-icons {
                color: $circle-nav-toggle-color;
            }

            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }

            // Navigation open
            &.circle-nav-open {
                transform: rotate(135deg);
                border: 2px solid maroon;

                i.material-icons {
                    color: #fff;
                }
            }
        }

        // Background panel
        .circle-nav-panel {
            transform: translate(-50%, -52.5%);
            transition: width 0.2s $cubic-bezier, height 0.2s $cubic-bezier;
            margin-left: math.div($circle-nav-panel-width, 2) + 12; // 12 to avoid cropping the shadow
            border-radius: 50%;
            // background: linear-gradient(
            //     to right,
            //     $circle-nav-panel-gradient-top,
            //     $circle-nav-panel-gradient-bottom
            // );
            width: 0;
            height: 0;

            // Navigation open
            &.circle-nav-open {
                // opacity: 0.7;
                box-shadow: -5px 6px 0 6px rgba(255, 95, 109, 0.33);
                width: $circle-nav-panel-width;
                height: $circle-nav-panel-height;
            }
        }

        // Navigation items
        .circle-nav-menu {
            width: math.div($circle-nav-panel-width, 2);
            height: math.div($circle-nav-panel-height, 2);

            .circle-nav-item {
                display: flex;
                position: absolute;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transform: rotate(-115deg);
                visibility: hidden;
                transition: bottom 0.2s $cubic-bezier, left 0.2s $cubic-bezier,
                    width 0.2s $cubic-bezier, height 0.2s $cubic-bezier;
                border-radius: 50%;
                background-color: transparent;
                width: 15px;
                height: 15px;

                // Show icon?
                @if $show-icon == true {
                    i {
                        display: block;
                    }
                } @else {
                    i {
                        display: none;
                    }
                }

                // Show text?
                @if $show-text == true {
                    span {
                        display: inline;
                        font-size: 0.7rem;
                    }
                } @else {
                    span {
                        display: none;
                    }
                }

                // Place the icons in the center of the panel before start transition
                &-1,
                &-2,
                &-3,
                &-4,
                &-5 {
                    bottom: math.div($circle-nav-panel-width, 2);
                    left: math.div($circle-nav-panel-width, 2);
                }

                i {
                    transition: all 1s $cubic-bezier;
                    color: $circle-nav-panel-gradient-top;
                    font-size: 0.1em;
                }
            }

            &.circle-nav-open {
                .circle-nav-item {
                    transform: rotate(0deg);
                    visibility: visible;
                    transition: bottom 0.3s $cubic-bezier,
                        left 0.3s $cubic-bezier, width 0.5s $cubic-bezier,
                        height 0.5s $cubic-bezier, transform 0.8s $cubic-bezier;
                    width: 40px;
                    height: 40px;

                    &:hover {
                        opacity: 0.8;
                        cursor: pointer;
                    }

                    i {
                        transition: all 1s $cubic-bezier;
                        font-size: 1.3em;
                    }

                    &.circle-nav-item-1 {
                        bottom: 80%;
                        left: 12%;
                        transition-delay: 0.1s;
                    }

                    &.circle-nav-item-2 {
                        bottom: 57%;
                        left: 20%;
                        transition-delay: 0.2s;
                    }

                    &.circle-nav-item-3 {
                        bottom: 36%;
                        left: 34%;
                        transition-delay: 0.3s;
                    }

                    &.circle-nav-item-4 {
                        bottom: 21.5%;
                        left: 52.8%;
                        transition-delay: 0.4s;
                    }

                    &.circle-nav-item-5 {
                        bottom: 12%;
                        left: 74.8%;
                        transition-delay: 0.5s;
                    }
                }
            }
        }
    }
}

@media (min-width: $breakpoint) {
    $show-icon: false;
    $show-text: true;

    .circle-nav-wrapper {
        position: relative;
        width: auto;
        // height: 50px;
        // line-height: 50px;
        overflow: auto;
        // background: linear-gradient(
        //     to right,
        //     $circle-nav-panel-gradient-top,
        //     $circle-nav-panel-gradient-bottom
        // );
        // margin-top: 40px;

        .circle-nav-toggle {
            display: none;
        }

        .circle-nav-panel {
            box-shadow: none;
            width: auto;
            max-width: auto;
            height: auto;
        }
        ul.circle-nav-menu {
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-left: 0;
        }
        .circle-nav-menu {
            display: flex;
            justify-content: space-around;
            margin-right: auto;
            margin-left: auto;
            width: $desktop-nav-width;
            max-width: 90vw;
            height: auto;

            .circle-nav-item {
                position: relative;
                visibility: visible;
                transition: color 0.2s ease-out;
                color: #fff;

                &:hover {
                    cursor: pointer;
                    color: #000;
                }

                &-1,
                &-2,
                &-3,
                &-4,
                &-5 {
                    display: flex;
                    bottom: auto;
                    left: auto;
                    align-items: center;

                    // Show icon?
                    @if $show-icon == true {
                        .material-icons {
                            display: block;
                        }
                    } @else {
                        .material-icons {
                            display: none;
                        }
                    }

                    // Show text?
                    @if $show-text == true {
                        span {
                            display: inline;
                            font-size: 0.8em;
                            text-transform: uppercase;
                        }
                    } @else {
                        span {
                            display: none;
                        }
                    }
                    .material-icons {
                        margin-right: 8px;
                        font-size: 1em;
                    }
                }
            }
        }
    }
}
