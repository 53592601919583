.markdown {
    @media print {
        *,
        *:before,
        *:after {
            box-shadow: none !important;
            background: transparent !important;
            color: #000 !important;
            text-shadow: none !important;
        }

        a,
        a:visited {
            text-decoration: underline;
        }

        a[href]:after {
            content: ' (' attr(href) ')';
        }

        abbr[title]:after {
            content: ' (' attr(title) ')';
        }

        a[href^='#']:after,
        a[href^='javascript:']:after {
            content: '';
        }

        pre,
        blockquote {
            page-break-inside: avoid;
            border: 1px solid #999;
        }

        thead {
            display: table-header-group;
        }

        tr,
        img {
            page-break-inside: avoid;
        }

        img {
            max-width: 100% !important;
        }

        p,
        h2,
        h3 {
            orphans: 3;
            widows: 3;
        }

        h2,
        h3 {
            page-break-after: avoid;
        }
    }

    @media screen and (min-width: 32rem) and (max-width: 48rem) {
        html {
            font-size: 15px;
        }
    }

    @media screen and (min-width: 48rem) {
        html {
            font-size: 16px;
        }
    }

    @import url(http://fonts.googleapis.com/css?family=Merriweather:300italic,300);

    html {
        font-size: 12px;
    }

    body {
        line-height: 1.85;
    }

    p,
    .splendor-p {
        margin-bottom: 1.3rem;
        font-size: 1rem;
    }

    h1,
    .splendor-h1,
    h2,
    .splendor-h2,
    h3,
    .splendor-h3,
    h4,
    .splendor-h4 {
        margin: 1.414rem 0 0.5rem;
        font-weight: inherit;
        line-height: 1.42;
    }

    h1,
    .splendor-h1 {
        margin-top: 0;
        font-size: 3.998rem;
    }

    h2,
    .splendor-h2 {
        font-size: 2.827rem;
    }

    h3,
    .splendor-h3 {
        font-size: 1.999rem;
    }

    h4,
    .splendor-h4 {
        font-size: 1.414rem;
    }

    h5,
    .splendor-h5 {
        font-size: 1.121rem;
    }

    h6,
    .splendor-h6 {
        font-size: 0.88rem;
    }

    small,
    .splendor-small {
        font-size: 0.707em;
    }

    /* https://github.com/mrmrs/fluidity */

    img,
    canvas,
    iframe,
    video,
    svg,
    select,
    textarea {
        max-width: 100%;
    }

    html {
        max-width: 100%;
        font-size: 18px;
    }

    body {
        margin: 0;
        max-width: 100%;
        color: #444;
        font-family: 'Merriweather', Georgia, serif;
    }

    /* === A bit of a gross hack so we can have bleeding divs/blockquotes. */

    p,
    *:not(div):not(img):not(body):not(html):not(li):not(blockquote):not(p):not(a):not(em):not(code):not(strong) {
        padding-right: 1rem;
        // margin: 1rem auto 1rem;
        // max-width: 36rem;
        padding-left: 0.5rem;
        hyphens: none;
    }

    div {
        width: 100%;
    }

    div img {
        width: 100%;
    }

    blockquote p {
        margin: 1rem auto 1rem;
        max-width: 48rem;
        font-style: italic;
        font-size: 1.5rem;
    }

    li {
        margin-left: 2rem;
    }

    /* Counteract the specificity of the gross *:not() chain. */

    h1 {
        padding: 4rem 0 !important;
    }

    /*  === End gross hack */

    p {
        height: auto;
        color: #555;
        line-height: 1.45;
    }

    pre,
    code {
        font-family: Menlo, Monaco, 'Courier New', monospace;
    }

    pre {
        background-color: #fafafa;
        padding: 1.125em;
        overflow-x: scroll;
        font-size: 0.8rem;
    }

    a,
    a:visited {
        color: #3498db;
    }

    a:hover,
    a:focus,
    a:active {
        color: #2980b9;
    }

    img[alt='wakatime'],
    img[alt='Netlify Status'] {
        // padding-bottom: 1rem;
        display: inline;
        width: unset;
        height: unset;
    }
    // padding-left: 1px;
    // padding-right: 10px;
    // margin-left: 10px;
    // margin-right: 15px;
}
