//*  __   ___  __   ___ ___
//* |__) |__  /__` |__   |
//* |  \ |___ .__/ |___  |

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
    // todo: fix mobile responsiveness with text clipping;
    // @media (max-width: 769px) {
    //     // overflow: visible !important;
    // }
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    display: block;
    max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

button {
    user-select: none;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

/**
 * Basic styles for links
 */

@mixin on-event($self: false) {
    @if $self {
        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    } @else {
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
}

a {
    text-decoration: none;

    @include on-event {
        text-decoration: underline;
    }
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 0px;
    background: #d58763;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: rgba(68, 68, 68, 1);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(107, 106, 206);
}
