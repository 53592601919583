.Map {
    border: 2px solid blanchedalmond;
    width: 200px;
    height: 200px;
    /* background-color: blanchedalmond; */
}

.Placeholder {
    border: 2px solid transparent;
    background-color: transparent;
    width: 200px;
    height: 200px;
}

.Pointer {
    --pin_height: 50px;
    position: absolute;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 var(--pin_height) rgba(0, 0, 0, 1);
    border: 0.5px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    background-image: url('https://ianrackson.com/assets/avatar.svg');
    background-position: center;
    background-size: var(--pin_height);
    background-repeat: no-repeat;
    background-attachment: center;
    width: var(--pin_height);
    height: var(--pin_height);
}
